import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';




function Knowledgebase(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="Knowledge Base" />

                <section className="content-inner linktree-wraper">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >

                                <h2 style={{textAlign:'center' ,textTransform:'capitalize' }}>F.A.Q</h2>
                        <ul className="linktree">
                            <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn"  rel="noreferrer" href="/supported-protocols" >
                              <span style={{marginLeft: '10px'}}>Supported Protocols and Integration Guidelines</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" rel="noreferrer" href="/wrong-token" >
                              <span style={{marginLeft: '10px'}}>Deposit of wrong or unsupported tokens to ZARC</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn"  rel="noreferrer" href="/zarc-token-recovery" >
                              <span style={{marginLeft: '10px'}}>ZARC token Recovery</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn"  rel="noreferrer" href="/redeem-token" >
                              <span style={{marginLeft: '10px'}}>Redeem ZARC tokens</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn"  rel="noreferrer" href="/us-residents" >
                              <span style={{marginLeft: '10px'}}>US residents</span>
                            </a>
                          </li>
                          </div>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                
            </div>
        </>
    )
}
export default Knowledgebase;
