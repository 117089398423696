import React, { useState } from 'react';
import {Link} from 'react-router-dom';


//Images
import Icon9 from './../../assets/images/home/1.png';
import Icon10 from './../../assets/images/home/2.png';
import Icon11 from './../../assets/images/home/3.png';
import Icon12 from './../../assets/images/home/4.png';
import Icon13 from './../../assets/images/home/5.png';
import Arrow from './../../assets/images/home/Arrow.png';
import pic1 from './../../assets/images/about/pic1.jpg';
import support1 from './../../assets/images/icons/support1.png';

const cardData = [
    {
        image: Icon9,
        link: 'https://docs.neonomad.finance/nni/',
        title: 'Unlock Unmatched Liquidity',
        description: "ZARC, South Africa's foremost stablecoin, offers unparalleled liquidity at unbeatable costs. We present a secure and robust starting liquidity, along with backup reserves, ensuring a stable and thriving ZARC ecosystem. By utilizing ZARC stablecoin, you gain access to a highly liquid asset that sets new standards in the market."
    },
    {
        image: Icon10,
        link: 'https://docs.neonomad.finance/nni/',
        title: 'Proof of Reserves',
        description: 'For optimal accountability, transparency, and price stability, ZARC upholds a 1:1 reserve ratio between the ZARCoin and the South African Rand. To ensure reassurance, ZARC is fully backed by NeoNomad\'s ZARC reserve, undergoing weekly audits. Our website provides easy access to an overview of all reserves and mints, offering clear visibility into this safeguarding process.'
    },
    {
        image: Icon11,
        link: 'https://docs.neonomad.finance/nni/',
        title: 'Regulatory Compliant',
        description: 'ZARC is establishing a relationship with South African, European, and UK financial institutions for the purpose of better servicing ZARC users in South Africa, Europe, and the U.K.'
    },
    {
        image: Icon12,
        link: 'https://docs.neonomad.finance/nni/',
        title: 'Unlock the Power of Multiple Blockchains',
        description: 'ZARC (PTY) Ltd harnesses the potential of a multi-chain ecosystem to bring you the ZARC stablecoin. Our commitment to providing seamless accessibility and versatility has led us to issue ZARC across the Solana, Binance, and Ethereum blockchains. By adopting this innovative approach, we ensure a greater level of user engagement and convenience for our valued community members. Explore the benefits of ZARC\'s presence on multiple blockchains, enabling you to leverage the strengths of each network and maximize your digital asset experience. Join ZARC today and experience the future of blockchain technology.'
    },
    {
        image: Icon13,
        link: 'https://app.neonomad.exchange/',
        title: '24/7 Customer Support Guiding Your Financial Journey',
        description: 'At ZARC, we understand the importance of having a reliable customer support team available to you at all times. That\'s why we have implemented the NeoNomad Zendesk live chat and ticketing functionality, enabling you to reach out to us whenever you need assistance. We take pride in our ability to provide quick and efficient responses, ensuring that we support you every step of the way on your financial journey. Whether you prefer logging a ticket or chatting directly with one of our agents, we are here to help.'
    }
];


function CardItem({ image, title, description, link }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const getTrimmedDescription = () => {
        const wordLimit = 20;
        const words = description.split(' ');
        if (words.length <= wordLimit) {
            return description;
        } else {
            return words.slice(0, wordLimit).join(' ');
        }
    };

    return (
        <div className="col-xl-4 col-md-6 m-b60">
            <div className="icon-bx-wraper style-3 text-center">
                <img src={image} alt="" />
                <div className="icon-content">
                    <h4 className="title" style={{ fontSize: '20px', paddingTop: '15px' }}>
                        {title}
                    </h4>
                    <p className="m-b0" style={{ color: 'black', fontSize: '13px' }}>
                        {isExpanded ? description : getTrimmedDescription()}
                    </p>
                    <button className="read-more-btn" onClick={handleReadMore}>
                        <span>{isExpanded ? 'Read Less' : 'Read More'}</span>
                        <img src={Arrow} alt="Arrow" />
                    </button>
                </div>
            </div>
        </div>
        
        
    );
}

function OneStop() {
    return (
        <>
            {cardData.map((item, ind) => (
                <CardItem
                    key={ind}
                    image={item.image}
                    title={item.title}
                    description={item.description}
                    link={item.link}
                />
            ))}
        </>
    );
}

export default OneStop;