import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';



function Redeemtoken(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="Redeem ZARC tokens to fiat currency" />
                
                <section className="content-inner linktree-wraper">
                <div className="container5" >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >

                        <p>Redeeming ZARC tokens entails exchanging your ZARC tokens for fiat currencies that will be deposited into a verified bank account.</p>
                        <p>You will be able to redeem your ZARC tokens once your ZARC account has been set up and verified and you have deposited enough ZARC tokens into your ZARC account.</p>
                        <h3>How to redeem ZARC tokens to my bank account</h3>
                        <p>1. Firstly sign in to your ZARC account</p>
                        <p>2. Then, navigate to the "x" page  </p>
                        <p>3. Enter the amount to be redeemed, select the ZARC token, and double-check that all information displayed is correct. When you've finished, click Confirm.</p>
                        <p>4. To finish, go to the Review Transaction page and review the transaction, then insert the Two-Factor Authenticator and click Redeem Funds.</p>
                        <p>5. Finally, you will now receive an email confirming receipt of your redemption request.</p>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                </div>
                
            
        </>
    )
}
export default Redeemtoken;
