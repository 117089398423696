import React, { useState } from "react";
import PageLayout from "./../layouts/PageLayout";
import logoeth from "./../assets/icons/ethlogo.png";
import logosol from "./../assets/icons/sologo.png";
import logobnb from "./../assets/icons/bnblogo.png";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div
        className="page-content"
        style={{ background: "white", marginTop: "100px" }}
      >
        <PageLayout desc={false} pageTitle="ZARC Transparency" />
        <div className="tab-container">
          <div className="tab-buttons">
            <button
              className={activeTab === 0 ? "active" : ""}
              onClick={() => handleTabClick(0)}
            >
              ZARC Issued
            </button>
            <button
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleTabClick(1)}
            >
              Reports And Reserves
            </button>
            <button
              className={activeTab === 2 ? "active" : ""}
              onClick={() => handleTabClick(2)}
            >
              Bridges
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 0 && (
              <div className="card-container">
                {/* <div className="card">
  <div className="logo-text-container" style={{ display: "flex", alignItems: "center" }}>
    <img src={logoeth} alt="Solana Logo" className="logotrans" />
    <div style={{ marginLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <h3 style={{ fontSize: "25px", marginBottom: "5px", float: "left", color:'#2C0882' }}>On Ethereum</h3>
      <p style={{ fontSize: "15px", float: "right" ,color:'black'}}>
        Difference: 80%
      </p>
    </div>
  </div>
  <div style={{ width: "100%", height: "20px", backgroundColor: "transparent", borderRadius: "8px", overflow: "hidden" }}>
    <div style={{ width: "100%", height: "50%", backgroundImage: "linear-gradient(to right, rgba(37, 149, 27, 0.59) 80%, rgba(213, 39, 53, 0.6) 80%)" , borderRadius:'8px' }}></div>
  </div>
  <p className="value1124">Total Authorized <a className="float-value"> R 1000</a></p>
  <p className="value1124">Less Authorized <a className="float-value"> R 1000</a></p>
</div>

                <div className="card">
  <div className="logo-text-container" style={{ display: "flex", alignItems: "center" }}>
    <img src={logosol} alt="Solana Logo" className="logotrans" />
    <div style={{ marginLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <h3 style={{ fontSize: "25px", marginBottom: "5px", float: "left" , color:'#2C0882' }}>On Solana</h3>
      <p style={{ fontSize: "15px", float: "right",color:'black' }}>
        Difference: 80%
      </p>
    </div>
  </div>
  <div style={{ width: "100%", height: "20px", backgroundColor: "transparent", borderRadius: "8px", overflow: "hidden" }}>
    <div style={{ width: "100%", height: "50%", backgroundImage: "linear-gradient(to right, rgba(37, 149, 27, 0.59) 80%, rgba(213, 39, 53, 0.6) 80%)" , borderRadius:'8px'  }}></div>
  </div>
  <p className="value1124">Total Authorized <a className="float-value"> R 1000</a></p>
  <p className="value1124">Less Authorized <a className="float-value"> R 1000</a></p>
</div>


   
<div className="card">
  <div className="logo-text-container" style={{ display: "flex", alignItems: "center" }}>
    <img src={logobnb} alt="Solana Logo" className="logotrans" />
    <div style={{ marginLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <h3 style={{ fontSize: "25px", marginBottom: "5px", float: "left" , color:'#2C0882' }}>On Binance</h3>
      <p style={{ fontSize: "15px", float: "right",color:'black' }}>
        Difference: 80%
      </p>
    </div>
  </div>
  <div style={{ width: "100%", height: "20px", backgroundColor: "transparent", borderRadius: "8px", overflow: "hidden" }}>
    <div style={{ width: "100%", height: "50%", backgroundImage: "linear-gradient(to right, rgba(37, 149, 27, 0.59) 80%, rgba(213, 39, 53, 0.6) 80%)" , borderRadius:'8px' }}></div>
  </div>
  <p className="value1124">Total Authorized <a className="float-value"> R 1000</a></p>
  <p className="value1124">Less Authorized <a className="float-value"> R 1000</a></p> 
  </div> */}
  <a href="" className="coming-soon">Coming Soon</a>


              </div>
            )}
              {activeTab === 1 && (
    <div className="card-container">
     <a href="" className="coming-soon">Coming Soon</a>

    </div>
  )}
  {activeTab === 2 && (
    <div className="card-container">
      <a href="" className="coming-soon">Coming Soon</a>

    </div>
  )}
</div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
