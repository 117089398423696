import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import Logo from './../assets/images/logo.png';
import LogoWhite from './../assets/images/logo-black.png';

function Header() {
  /* for sticky header */
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <header className="site-header mo-left header header-transparent style-1">
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? 'is-fixed' : ''
          }`}
        >
          <div className="main-bar clearfix">
            <div className="container clearfix">
              <div className="logo-header">
                <Link to={'/'} className="logo-dark">
                  <img src={Logo} alt="" />
                </Link>
                <Link to={'/'} className="logo-light">
                  <img src={LogoWhite} alt="" />
                </Link>
              </div>

              <button
                type="button"
                className={`navbar-toggler  navicon justify-content-end ${
                  sidebarOpen ? 'open' : 'collapsed'
                }`}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div
                className={`header-nav navbar-collapse collapse justify-content-end ${
                  sidebarOpen ? 'show' : ''
                }`}
                id="navbarNavDropdown"
              >
                <ul className="nav navbar-nav navbar">
                  <li>
                    <NavLink to={'/'}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/about-us'}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/linktree'}>Socials</NavLink>
                  </li>
                  <li>
                    <NavLink to={'https://www.neonomad.io/blog'} target="_blank">
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={'/transparency'}>Transparency</NavLink>
                  </li>

                  {/* <li className={`sub-menu-down ${showMenu ? "open" : ""}`} id="menushow"
                      onClick={()=>setShowMenu(!showMenu)}
                      
                    >
                      <Link to={"#"}>Blog</Link>
                      <ul className="sub-menu">
                          <li><NavLink to={"/blog-grid"}>Blogs</NavLink></li>
                      </ul>
                    </li> */}
                   <li>
                    <NavLink to={'https://neonomadhelp.zendesk.com/hc/en-us'} target="_blank">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>

                <div className="header-bottom">
                  <div className="dz-social-icon">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="fab fa-facebook-f"
                          rel="noreferrer"
                          href="https://www.facebook.com/neonomadfinance"
                        ></a>
                      </li>{' '}
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="fab fa-twitter"
                          rel="noreferrer"
                          href="https://twitter.com/NeoNomadFinance"
                        ></a>
                      </li>{' '}
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="fab fa-linkedin-in"
                          rel="noreferrer"
                          href="https://www.linkedin.com/company/neonomad-finance"
                        ></a>
                      </li>{' '}
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="fab fa-instagram"
                          rel="noreferrer"
                          href="https://www.instagram.com/neonomad.xchange/"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Login and Register buttons */}
                <div className="extra-nav">
                  <div className="extra-cell" >
                    <a
                      className="btn-top-reg"
                      target="_blank"
                      rel="noreferrer"
                      href="https://beta-app.neonomad.io/login"
                    >
                      Login
                    </a>
                    <a
                      className="btn-top-reg"
                      target="_blank"
                      rel="noreferrer"
                      href="https://beta-app.neonomad.io/register"
                    >
                      Register
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
