import React, { useState, useEffect } from 'react';

import coin1 from './../../assets/images/coins/coin1.png';
import coin3 from './../../assets/images/coins/coin3.png';
import coin4 from './../../assets/images/coins/coin4.png';
import coin2 from './../../assets/images/coins/coin2.png';

const BannerCard = () => {
    const [cardData, setCardData] = useState([
        { image: coin4, title: 'ZARC', subtitle: 'USDC', price: `R ${'1'}`},
        { image: coin3, title: 'ZARC', subtitle: 'NNI',  price: `NNI ${'1'}`},
        { image: coin1, title: 'ZARC', subtitle: 'BTC',  price: `BTC ${'1'}`},
        { image: coin2, title: 'ZARC', subtitle: 'SOL',  price: `SOL ${'1'}`},

    ]);
    

    const [lastApiCallTime, setLastApiCallTime] = useState(0);

    useEffect(() => {
        const now = Date.now();
        const timeSinceLastApiCall = now - lastApiCallTime;

        // Only make the API call if 30 seconds have passed since the last call
        if (timeSinceLastApiCall >= 30000) {
            fetch('https://public-api.birdeye.so/public/price?address=Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB')
                .then(response => response.json())
                .then(json => {
                    // Update the price of coin4 in the cardData array and format it to display only 3 decimal points
                    const updatedCardData = [...cardData];
                    updatedCardData[0].price = `R ${(1 / parseFloat(json.data.value)).toFixed(2)}`;
                    
                    setCardData(updatedCardData);

                    // Update the last API call time
                    setLastApiCallTime(now);
                })
                .catch(error => console.error(error));
        }
    }, [cardData, lastApiCallTime, setLastApiCallTime]);

    useEffect(() => {
        const now = Date.now();
        const timeSinceLastApiCall = now - lastApiCallTime;

        // Only make the API call if 30 seconds have passed since the last call
        if (timeSinceLastApiCall >= 30000) {
            fetch('https://public-api.birdeye.so/public/price?address=Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB')
                .then(response => response.json())
                .then(json => {
                    // Update the price of coin4 in the cardData array and format it to display only 3 decimal points
                    const updatedCardData = [...cardData];
                    updatedCardData[1].price = `NNI ${( parseFloat(json.data.value) / 0.015).toFixed(4)}`;
                    setCardData(updatedCardData);
 
                    // Update the last API call time
                    setLastApiCallTime(now);
                })
                .catch(error => console.error(error));
        }
    }, [cardData, lastApiCallTime, setLastApiCallTime]);

    useEffect(() => {
        const now = Date.now();
        const timeSinceLastApiCall = now - lastApiCallTime;

        // Only make the API call if 30 seconds have passed since the last call
        if (timeSinceLastApiCall >= 30000) {
            fetch('https://public-api.birdeye.so/public/price?address=Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB')
                .then(response => response.json())
                .then(json => {
                    // Update the price of coin4 in the cardData array and format it to display only 3 decimal points
                    const updatedCardData = [...cardData];
                    updatedCardData[2].price = `BTC ${(parseFloat(json.data.value) / 30000).toFixed(8)}`;
                    setCardData(updatedCardData);

                    // Update the last API call time
                    setLastApiCallTime(now);
                })
                .catch(error => console.error(error));
        }
    }, [cardData, lastApiCallTime, setLastApiCallTime]);

    useEffect(() => {
        const now = Date.now();
        const timeSinceLastApiCall = now - lastApiCallTime;

        // Only make the API call if 30 seconds have passed since the last call
        if (timeSinceLastApiCall >= 30000) {
            fetch('https://public-api.birdeye.so/public/price?address=Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB')
                .then(response => response.json())
                .then(json => {
                    // Update the price of coin4 in the cardData array and format it to display only 3 decimal points
                    const updatedCardData = [...cardData];
                    updatedCardData[3].price = `SOL ${( parseFloat(json.data.value) / 24.19).toFixed(7)}`;
                    setCardData(updatedCardData);

                    // Update the last API call time
                    setLastApiCallTime(now);
                })
                .catch(error => console.error(error));
        }
    }, [cardData, lastApiCallTime, setLastApiCallTime]);

    
    return (
        <>
            {cardData.map((data, index) => (
                <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={index}>
                    <div className="icon-bx-wraper style-1 box-hover ">
                        <div className="icon-media" >
                            <img src={data.image} alt="" />
                            <div className="icon-info" >
                                <h5 className="title">{data.title}</h5>
                                <span style={{fontWeight:"bold"}}>{data.subtitle}</span>
                            </div>
                        </div>
                        <div className="icon-content">
                            <ul className="price">
                                <li>
                                    {/* <p>R 1.00</p> */}
                                    <h6 className="mb-0 amount">{data.price}</h6>
                                    <span className={`percentage ${index === 2 ? "text-green" : "text-red"}`}>{data.percent}</span>
                                </li>
                                <li>
                                    <span>Latest price</span>
                                    <span>24h change</span>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
            ))}
        </>
    )
}

export default BannerCard;
