import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';

import baner1 from './../assets/images/home-banner/ZARC_TOKEN.png'


function Linktree(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'50px'}}>
                {/* <PageLayout desc={false} pageTitle="Socials" /> */}

                <section className="content-inner linktree-wraper" style={{background:'white'}}>
                  <div className="container " >
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >
                      <img src={baner1} alt="Image Description" style={{width:'240px' , height:'240px' , margin: 'auto', display: 'flex', }} />
                                <p style={{textAlign:'center' , marginRight: "15px"}}>#DeFi #CeFi #NFTS #MultiChain #NeoNomad #Marketplace #Crypto</p>

                                <h2 style={{textAlign:'center' ,textTransform:'capitalize' }}>Socials</h2>
                        <ul className="linktree">
                            <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/zarcstablecoin/" >
                              <i className="fa-brands fa-facebook-f" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>LinkedIn</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://www.instagram.com/neonomad.xchange/" >
                              <i className="fa-brands fa-instagram" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>Instagram</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://twitter.com/ZARCstablecoin" >
                              <i className="fa-brands fa-twitter" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>Twitter</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC_xQFH_TCyBH-Miy76VaWDQ" >
                              <i className="fab fa-youtube" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>YouTube</span>
                            </a>
                          </li>
                          </div>
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://t.me/NeoNomadFinance" >
                              <i className="fab fa-telegram" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>Telegram</span>
                            </a>
                          </li>
                          </div>
                          
                          
                          <div className="row col-md-12 col-sm-12">
                          <li>
                            <a className="social-btn" target="_blank" rel="noreferrer" href="https://www.reddit.com/r/NeoNomadFinance" >
                              <i className="fab fa-reddit" style={{ display:'block', position:'absolute', paddingRight:'300px', paddingTop:'4px'}}></i> 
                              <span style={{marginLeft: '10px'}}>Reddit</span>
                            </a>
                          </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                
            </div>
        </>
    )
}
export default Linktree;
