import React from 'react';
import { BrowserRouter, Route, Routes  } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import Pricing from './Pricing';
import BlogList from './BlogList';
import BlogGrid from './BlogGrid';
import BlogDetails from './BlogDetails';
import ContactUs from './ContactUs';
import Linktree from './Linktree'
import Blogpost1 from './Blogpost1';
import Transparency from './Transparency'
import Knowledgebase from './Knowledgebase';
import Supportedprotocols from './Supportedprotocols';
import Wrongtoken from './Wrongtokens';
import Tokenrecovery from './tokenrecovery';
import Redeemtoken from './Redeemtoken';
import Usresidents from './usresidents';

function Index(){
  return(
    <BrowserRouter basename="/">
      <div className="page-wraper">
        <Header />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about-us' exact element={<AboutUs />} />
          <Route path='/pricing' exact element={<Pricing />} />
          <Route path='/blog-list' exact element={<BlogList />} />
          <Route path='/blog-grid' exact element={<BlogGrid />} />
          <Route path='/blog-details' exact element={<BlogDetails />} />
          <Route path='/contact-us' exact element={<ContactUs />} />
          <Route path='/linktree' exact element={<Linktree />} />
          <Route path='/knowledgebase' exact element={<Knowledgebase />} />
          <Route path='/supported-protocols' exact element={<Supportedprotocols />} />
          <Route path='/wrong-token' exact element={<Wrongtoken />} />
          <Route path='/redeem-token' exact element={<Redeemtoken />} />
          <Route path='/us-residents' exact element={<Usresidents />} />
          <Route path='/zarc-token-recovery' exact element={<Tokenrecovery />} />
          <Route path='/Transparency' exact element={<Transparency />} />
          <Route path='/ZARC-Stablecoin' exact element={<Blogpost1 />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </div>
    </BrowserRouter>     
  )
} 
export default Index;
