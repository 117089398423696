import React,{useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

//components
import BannerCard from './../components/Home/BannerCard';
import OneStop from './../components/Home/OneStop';
import RecentNews from './../components/Home/RecentNews';

//images
import baner1 from './../assets/images/home-banner/ZARC_COIN1.png';
import baner2 from './../assets/images/home-banner/ZARC_COIN2.png';
import Shape1 from './../assets/images/home-banner/Cube-01.png';
import Shape3 from './../assets/images/home-banner/triangle.png';
import wallet from './../assets/images/icons/wallet.svg';
import friend from './../assets/images/icons/friend.svg';

import coin1 from './../assets/images/coins/coin1.png';
import coin3 from './../assets/images/coins/coin3.png';
import coin4 from './../assets/images/coins/coin4.png';

import bloglg from './../assets/images/blog/blog-ig.png';
import avatar3 from './../assets/images/avatar/avatar3.jpg';


const trustBlog = [
	{image: friend, title:'ZARC FOR INDIVIDUALS'}, 
	{image: friend, title:'ZARC FOR MERCHANTS'},
	{image: friend, title:'ZARC FOR EXCHANGES'},  
];

function Home(){
	const nav = useNavigate();
    const formDetails = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };
	
	const [selecttext, setSelectText] = useState([coin4, 'Bitcoin']);
	return(
		<>
			<div className="page-content" style={{marginTop:'100px'}}>				
				<div className="main-bnr style-1 bg-main">
					<div className="container" >
						<div className="row align-items-center">
							<div className="col-12 text-left">
								<p className='h124'> Money FASTER THAN YOU CAN SAY B.A.N.K</p>
								<p className='p1234'>ZARC: The Stablecoin Revolutionizing the South African Financial Industry. 100% Backed, Transparent, and Fully Compliant. Join the Financial Ecosystem that Never Sleeps. </p>
								<Link to={"/about-us"} className="btn-left-bnr" >Get Started</Link>
								<ul className="image-before">
									
									<li className="right-img"><img src={baner2} alt="" /></li>
									
								</ul>
							</div>
						</div>
					</div>
					
				</div>
				
				<div className="clearfix bg-primary-light " style={{background:'white'}}>
					<div className="container" style={{marginTop: '100px'}}>
						<div className="currancy-wrapper">
							<div className="row justify-content-center padding5">
								<BannerCard />
							</div>
						</div>
					</div>
				</div>
				{/* <div className='bg-bnr-2'></div> */}
				<section className="clearfix section-wrapper1 bg-driving ">
					<div className="container124 ">
						<div className="content-inner-1">
							<div className="section-Driving-text">
								<h2 style={{color:'white'}}>DRIVING THE FUTURE OF FINANCE</h2>
								<p style={{ color:'#8573FE', fontWeight:'bold'}} >No Middlemen, No Excess Fees: Transcend Borders.  </p>
								<p style={{ color:'white'}}>Save Costs and Build Your Business: Empowering the People. Available on Multiple Blockchains (Solana, Binance, Ethereum). </p>
								<div className="row">
								<div className="col-lg-512"  >
<Link to={"https://docs.neonomad.finance/nni/"} className="btn-driving" >For Individuals</Link>
<Link to={"https://docs.neonomad.finance/nni/"} className="btn-driving" >For Merchants</Link>
<Link to={"https://docs.neonomad.finance/nni/"} className="btn-driving" >For Exchanges</Link>
</div>
</div>
							</div>
						
						</div>
					</div>
					
					
				</section>
				{/* <div className='bg-bnr-3'></div> */}
				<section className="content-inner bg-light icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-left border-backed ">
							<h2 className="h254 ">100% BACKED AND FULLY TRANSPARENT</h2>
							<p className='p1235'>At NeoNomad, every ZARC in circulation is backed in a 1:1 ratio by an equivalent amount of fiat
							 currency held in reserves by  NeoNomad. As the custodian of the backing asset, we act as a trusted third party responsible for its management. 
							 To mitigate risks, we have implemented a simple system that reduces complexity in conducting audits for both fiat and crypto,
							 while enhancing their security and transparency. This approach ensures the reliability and verifiability of our audits. </p>
						</div>
						<div className="row sp60">
							<OneStop />
						</div>
					</div>
					
				</section>
				
			</div>		
		</>
	)
} 
export default Home;