import React from 'react';
import { Link} from 'react-router-dom';

//images
import shape1 from './../assets/images/home/mapbase.png';
import bgimage from './../assets/images/home/mapbase.png';
import logowhite from './../assets/images/logo-black.png';
import flags from './../assets/images/footer/world-map-with-flags1.png';



function Footer(){
    return(
        <>
            <footer className="site-footer style-1" id="footer">
                <img className="bg-shape1" src={shape1} alt="" />
                <div className="footer-top background-luminosity" style={{backgroundImage: "url("+ bgimage +")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12" >
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-white">
                                        <Link to={"/"}><img src={logowhite} alt="" /></Link>
                                    </div>
                                    <p style={{color:'black'}}>ZARC: The Stablecoin Revolutionizing the South African Financial Industry. 100% Backed, Transparent, and Fully Compliant. Join the Financial Ecosystem that Never Sleeps. </p>
                                    <div className="dz-social-icon  space-10">
                                        <ul>
                                            <li><a target="_blank" rel="noreferrer" className="fab fa-facebook-f" href="https://www.facebook.com/neonomadfinance"></a></li>{" "}
                                            <li><a target="_blank" rel="noreferrer" className="fab fa-instagram" href="https://www.instagram.com/neonomad.xchange/"></a></li>{" "}
                                            <li><a target="_blank" rel="noreferrer" className="fab fa-twitter" href="https://twitter.com/NeoNomadFinance"></a></li>{" "}
                                            <li><a target="_blank" rel="noreferrer" className="fab fa-youtube" href="https://www.youtube.com/channel/UC_xQFH_TCyBH-Miy76VaWDQ"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6" >
                                <div className="widget widget_services">
                                    <h4 className="widget-title" style={{color:'black'}}>Other Links</h4>
                                    <ul>
                                        <li style={{color:'black'}}><Link to={"/about-us" }>About Us</Link></li>
                                        <li style={{color:'black'}}><Link to={"/linktree"}>Socials</Link></li>
                                        
                                        <li style={{color:'black'}}><Link to={"/contact-us"}>Contact Us</Link></li>
                                        <li style={{color:'black'}}><Link to={"/knowledgebase"}>Knowledge Base</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6" >
                                <div className="widget recent-posts-entry">
                                    <h4 className="widget-title" style={{color:'black'}}>Blog Posts</h4>
                                    <div className="widget-post-bx">
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title" style={{color:'black !important'}}><Link to={"https://www.neonomad.io/blog/zarc-stablecoin"} style={{color:'black'}}>ZARC NeoNomad’s 1:1 pegged South African Rand stablecoin..</Link></h6>
                                                <span className="post-date" style={{color:'#2735D5'}}> 26 Oct 2022</span>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        {/* <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><Link to={"/things-that-make-you-love-cryptocurrency"} style={{color:'black'}}>A cryptocurrency is a digital currency.</Link></h6>
                                                <span className="post-date" style={{color:'#2735D5'}}> AUGUST 22, 2023</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-12" >
                                <div className="widget widget_locations">
                                    <h4 className="widget-title"style={{color:'black'}}>Locations</h4>
                                    <div className="clearfix">
                                        <h6 className="title" style={{color:'#2735D5'}}>South Africa</h6>
                                        <p style={{color:'black'}}>Unit105B, The Cape Old Cape Quarter, 72 Waterkant Street, Green Point, Cape Town, Western Cape, South Africa, 8001</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer-bottom text-center">
                    <div className="container">
                        <span className="copyright-text">Copyright © 2023 <a href="https://neonomad.io" target="_blank" rel="noreferrer">NeoNomad</a>. All rights reserved.</span>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;