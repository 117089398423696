import React, { useState } from 'react';

import {Link} from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';
//images
import pic1  from './../assets/images/blog/Zarcoin-1.png';
import pic2  from './../assets/images/blog/pic2.jpg';
import pic3  from './../assets/images/blog/pic3.jpg';
import pic4  from './../assets/images/blog/pic4.jpg';
import pic5  from './../assets/images/blog/pic5.jpg';
import pic6  from './../assets/images/blog/pic6.jpg';
import avatar1  from './../assets/images/avatar/ZARC_TOKEN.png';
import avatar2  from './../assets/images/avatar/avatar2.jpg';
import avatar3  from './../assets/images/avatar/avatar3.jpg';

const gridData = [
    {image1: pic1,image2: avatar1,title: 'ZARC Stablecoin',date: '26-10-2022',author: 'NeoNomad',link: '/ZARC-Stablecoin', intro: 'ZARC NeoNomad’s 1:1 pegged South African Rand stablecoin.'},
    {image1: pic2, image2: avatar2, title:'7 Ingenious Ways You Can Do With Crypto',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic3, image2: avatar3, title:'14 Days To A Better Crypto Currency',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic4, image2: avatar2, title:'Why You Should Not Go To Cryptocurrency.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic5, image2: avatar1, title:'Five Easy Rules Of bitcoin.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic6, image2: avatar3, title:'14 Days To A Better Crypto Currency.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic4, image2: avatar2, title:'Why You Should Not Go To Cryptocurrency.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic5, image2: avatar1, title:'Five Easy Rules Of bitcoin.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    {image1: pic6, image2: avatar3, title:'14 Days To A Better Crypto Currency.',date: '2022-05-12',author: 'Noare',link: '/blog-details',intro: 'test'},
    
];

function BlogGrid(){
    const pageSize = 6; // set the number of items per page
    const [currentPage, setCurrentPage] = useState(1); // set the current page state
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    
    const currentData = gridData.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout pageTitle="ZARC BLOG" />
                <section className="content-inner">
                    <div className="container">
                        <div className="row">
                            {currentData.map((item, ind)=>(
                                <div className="col-md-6 col-xl-4 m-b30" key={ind}>
                                    <div className="dz-card style-1 blog-lg overlay-shine">
                                        <div className="dz-media">
                                            <Link to={item.link}><img src={item.image1} alt="" /></Link>
                                        </div>
                                        <div className="dz-info">
                                            <div className="dz-meta">
                                                <ul>
                                                    <li className="post-author">
                                                        <Link to={item.link}>
                                                            <img src={item.image2} alt="" />
                                                            <span>By {item.author}</span>
                                                        </Link>
                                                    </li>
                                                    <li className="post-date"><Link to={item.link}>{item.date}</Link></li>
                                                </ul>
                                            </div>
                                            <h4 className="dz-title"><Link to={item.link}>{item.title}</Link></h4>
                                            <p className='short-intro'><Link to={item.link}>{item.intro}</Link></p>
                                            <Link to={item.link} className="btn btn-primary">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 m-b30 m-t30 m-lg-t10">
                                <nav aria-label="Blog Pagination">
                                    <ul className="pagination style-1 text-center">
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <Link to={"#"} className="page-link prev" onClick={() => handlePageChange(currentPage - 1)}>
                                                <i className="fas fa-chevron-left"></i>
                                            </Link>
                                        </li>
                                        {Array(Math.ceil(gridData.length / pageSize)).fill().map((_, index) => (
                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                <Link to={"#"} className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</Link>
                                            </li>
                                        ))}
                                        <li className={`page-item ${currentPage === Math.ceil(gridData.length / pageSize) ? 'disabled' : ''}`}>
                                            <Link to={"#"} className="page-link next" onClick={() => handlePageChange(currentPage + 1)}>
                                                <i className="fas fa-chevron-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default BlogGrid;