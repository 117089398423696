import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Images
import Icon9 from './../../assets/images/aboutus/1.png';
import Icon10 from './../../assets/images/aboutus/2.png';
import Icon11 from './../../assets/images/aboutus/3.png';
import Icon12 from './../../assets/images/aboutus/4.png';
import Icon13 from './../../assets/images/aboutus/5.png';
import Arrow from './../../assets/images/home/Arrow.png';

const cardData = [
  {
    image: Icon9,
    title: 'Financial Stability',
    description: 'At ZARC, our utmost dedication lies in delivering a stablecoin that guarantees security, reliability, and transparency. Our stablecoin is tethered to the South African Rand (ZAR) at a fixed 1:1 ratio, ensuring stability for our users.  ',
    expandedText: ''
  },
  {
    image: Icon10,
    title: 'Exponential Growth',
    description: 'We are dedicated to fostering exponential economic growth, facilitating development, and promoting financial inclusion on a global scale. Our mission is to offer users a secure and reliable asset that is not only easy to use but also comprehensible, enabling it to effectively support the diverse needs of the global economy.  ',
    expandedText: ''
  },
  {
    image: Icon11,
    title: 'Unparalleled Liquidity',
    description: 'Leverage the unparalleled liquidity of ZARC; a highly liquid coin facilitating seamless trading across multiple exchanges. Enjoy the benefit of swift and effortless fund deposits and withdrawals, ensuring hassle-free liquid investments for investors. .',
    expandedText: ''
  },
  {
    image: Icon12,
    title: 'Fully Transparent',
    description: 'As part of our commitment to transparency, ZARC ensures that the balance of ZAR fiat currency held in reserves is always equal to or greater than the number of ZARC in circulation, providing our users with complete visibility and trust in our financial operations.  ',
    expandedText: ''
  },
  {
    image: Icon13,
    title: 'Bank To Blockchain',
    description: 'With ZARC, you can easily transfer your fiat currency into our secure and reliable blockchain-powered stablecoin ecosystem, '+
      'allowing you to move seamlessly from traditional banking to cryptocurrency. ',
    expandedText: ''
  }
];

function Card({ image, title, description, expandedText }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="col-xl-4 col-md-6 m-b60" style={{paddingBottom: "35px"}} >
    <div className=" text-center card-text-a" >
        
        <div className="icon-media">
          <img src={image} alt="" />
        </div>
        <div className="icon-content">
          <h4 className="title">{title}</h4>
          {expanded ? (
            <div>
              <p className="m-b0">{description}</p>
              <p>{expandedText}</p>
            </div>
          ) : (
            <p className="m-b0">{description.substring(0, 100)}...</p>
          )}
         <button  className="expand-button" onClick={() => setExpanded(!expanded)}  >
  {expanded ? 'Read Less' : 'Read More' }
  <img src={Arrow} alt="Arrow"  />
</button>

        </div>
      </div>
    </div>
  );
}

function Ourmission() {
  return <div className="row">{cardData.map(Card)}</div>;
}

export default Ourmission;