import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';



function Usresidents(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="US Residents" />
                
                <section className="content-inner linktree-wraper">
                <div className="container5" >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >

                        <h3>What ZARC features can U.S. residents access?</h3>
                        <p>Unfortunately, ZARC has decided to not serve U.S. 
                            individual and corporate customers altogether.  
                            no issuance or redeeming services will be available to these users.</p>
                            <p>Exceptions to these provisions may be made by ZARC, in its sole discretion, for entities that are:</p>
                            <p>1. Established or organized outside of the United States or its territorial or insular possessions </p>
                            <p>2. Eligible Contract Participants pursuant to U.S. law</p>
                            <h3>Do I qualify as an Eligible Contract Participant?</h3>
                            <p>An Eligible Contract Participant includes a corporation that has total assets exceeding $10,000,000 and is incorporated 
                                in a jurisdiction outside of the United States or its territories or insular possessions. 
                                This will be the principal basis upon which we will continue to do business with selected U.S. persons</p>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                </div>
                
            
        </>
    )
}
export default Usresidents;
