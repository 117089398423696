import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';



function Wrongtoken(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="Deposit of wrong or unsupported tokens to ZARC" />
                
                <section className="content-inner linktree-wraper">
                <div className="container2" >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >

                        <p>Token recovery is a complex, expensive, risky and time-consuming process that assists in the returning/restoring of funds that have been mistakenly sent. </p>
                        <p>Wrong or unsupported tokens are tokens that are any tokens which is not accepted on ZARC. gateway. You can see the complete list of tokens <a href="test">here</a></p>
                        <p style={{color:'red'}}>Important: Please exercise extreme care when depositing tokens.</p>
                        <p>ZARC does not offer a token recovery service for deposit mistakes related to deposits of tokens not supported by ZARC. </p>
                        <p>In limited cases, as determined by ZARC in its sole and absolute discretion (e.g., significant losses resulting from one or multiple deposit mistakes), 
                        ZARC may attempt to assist you in recovering your tokens.
                        ZARC may also charge you a recovery fee, based on the level of difficulty of the recovery and the time needed to help recover all or part of your tokens.</p>
                        <p>For the policy on ZARC Token Recoveries please read <a href="/zarc-token-recovery">here.</a> </p>
                        <p>As recovery is handled case by case, if you have made a mistake while transferring funds, feel free to reach out to ZARC Support for assistance. </p>
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                </div>
                
            
        </>
    )
}
export default Wrongtoken;
