import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';



function Supportedprotocols(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="Supported Protocols and Integration Guidelines" />
                
                <section className="content-inner linktree-wraper">
                <div className="container2" >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12"style={{color:'black'}} >

                                <p>We appreciate your interest in integrating ZARC tokens into your platform.</p>
                                <p>ZARC tokens exist on multiple blockchains and should be treated equally and interchangeably, i.e. 1 ZARC token on Blockchain X = 1 ZARC token on Blockchain Y</p>
                                <h5>Currently Supported Asset Protocols:</h5>
                                <ul>
                                <li>• Solana token via Solana Blockchain</li>
                                <li>• ERC20 Token via Ethereum Blockchain</li>
                                <li>• BEP20 Token via BNB Chain</li>
                                </ul>

                                <h5>Solana token via Solana Blockchain</h5>
                                <p>To integrate ZARC Solana Token on the Solana Blockchain use the ZARC contract address: </p> 
                                <a style={{color:'purple', fontWeight:'bold'}} href="https://solscan.io/token/Eh2Lea2KWLryBQaQEGiJFdRzRPjTeTXaJ4ZaCsfDuAhB">Solscan</a>

                                <h5>ERC20 token via Ethereum Blockchain</h5>
                                <p>To integrate ZARC Ethereum Token on the Ethereum Blockchain use the ZARC contract address: </p> 
                                <a style={{color:'purple', fontWeight:'bold'}} href="https://etherscan.io/token/0x7a724fe74702d3770e595d4063ff2baf19cdbebc">Etherscan</a>

                                <h5>BEP20 token via BNB Chain Blockchain</h5>
                                <p>To integrate ZARC BNB Token on the BNB chain Blockchain use the ZARC contract address: </p> 
                                <a style={{color:'purple', fontWeight:'bold'}} href="https://bscscan.com/token/0x18e0662b2da216bc06aa3abcd5ceae88f372198b">BSC Scan</a>
                        
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                </div>
                
            
        </>
    )
}
export default Supportedprotocols;
