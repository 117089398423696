import React from 'react';
import {useNavigate} from 'react-router-dom';

import PageLayout from '../layouts/PageLayout';



function Tokenrecovery(){
    const nav = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        nav("/");
    };

    
    return(
        <>
            <div className="page-content"style={{marginTop:'100px'}}>
                <PageLayout desc={false} pageTitle="ZARC token Recovery" />
                
                <section className="content-inner linktree-wraper">
                <div className="container5" >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12" >

                        <p style={{color:'red'}}>Important: ZARC Token Recoveries are only available to entities that are not prohibited from using ZARC services as outlined in our Terms of Service</p>
                        <p>ZARC tokens should be sent only to addresses that explicitly state they support ZARC tokens. Transferring a ZARC Token to any other destination could result in a total loss of funds.</p>
                        <p>ZARC token recovery is the process of returning ZARC tokens mistakenly deposited.  </p>
                        <p>Note: If ZARC tokens have been transferred to an unsupported (unknown) destination, please contact the receiving party immediately and request their assistance in recovering/returning the funds. 
                        ZARC is unable to assist in this situation</p>
                        <p>In specific cases, as determined by ZARC in its sole and absolute discretion, ZARC may attempt to assist in the recovery of ZARC tokens (depending on the blockchain) that have been transferred to:</p>
                        <ul>
                            <li>• A destination that ZARC determines to be recoverable</li>
                        </ul>
                        <p>Important: Any legal issues related to Law Enforcement requests/thefts/hacks/scams/similar should be reported to the ZARC team. </p>
                        <h3>What are the accepted recovery requests</h3>
                        <p>Recovery requests for amounts exceeding $1000 will be accepted, subject to the following procedures:</p>
                        <ul>
                            <p>• ZARC will initially evaluate and assess if the recovery is feasible. If after this evaluation, recovery is deemed not to be viable, at ZARC sole and absolute discretion, the ZARC tokens will not be recovered. This evaluation is final. Important:
                                 If it is decided that a recovery will be tried, ZARC will attempt a recovery, but a successful outcome is not and cannot be guaranteed.</p>
                            <p>• ZARC will charge you a fee of up to 10% of the recovery amount or $1000 (whichever is greater) for the ZARC tokens recovery. Note: The fee will be deducted from the recovery amount.</p>
                            <p>• There is no time frame or time limit for any discretionary recovery process to be undertaken by ZARC. Due to the complex procedures required it is not uncommon for recovery requests to sometimes take several months. </p>
                            <p style={{color:'red'}}>Important Note: Please do not contact us; instead, wait for notification. ZARC will not have any updates to communicate until the process is completed.</p>
                            <p>• You will have to become a verified user under ZARC Terms of Service</p>
                        </ul>

                        <h3>Required information for recovery</h3>
                        <p>To submit a request for recovery, you will need to contact ZARC Support and provide the following information:</p>
                        <p>• Transaction ID</p>
                        <p>• Deposit Address</p>
                        <p>• Amount</p>
                        <p>• A complete description of the actions/events leading to the current problem/issue</p>
                        <p>• A complete description of everything you have tried/done to recover the funds so far Note: For transfers to the ZARC Operation Contract this can be omitted</p>
                        <p>• We may ask you to provide additional documents as part of the recovery process. Failure to provide such documents may end the recovery process, at ZARC sole discretion.</p>

                        <p>Note: While screenshots/pictures may be included to help illustrate the issue, any Address or Transaction ID/hash should be provided as text.</p>

                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                </div>
                
            
        </>
    )
}
export default Tokenrecovery;
