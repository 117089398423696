import React,{useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ModalVideo from 'react-modal-video'

import PageLayout from './../layouts/PageLayout';
import RecentNews from './../components/Home/RecentNews';

//Images
import Shape1 from './../assets/images/home-banner/shape1.png';


import videobox from './../assets/images/home-banner/22.png';

import bloglg from './../assets/images/blog/blog-ig.png';
import avatar3 from './../assets/images/avatar/avatar3.jpg';

import TeamMember1 from './../assets/images/team/Hanres.jpg'
import TeamMember2 from './../assets/images/team/KyleM.jpg'
import TeamMember3 from './../assets/images/team/Charne.jpg'
import TeamMember4 from './../assets/images/team/Denise.jpg'
import TeamMember5 from './../assets/images/team/henro.jpg'
import TeamMember6 from './../assets/images/team/Kp.jpg'

import OneStop from './../components/About/Ourmission';


function AboutUs(){
    const nav = useNavigate();
    const formDetails = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };
    const [isOpen, setOpen] = useState(false)
    return(
        <>
            <div className="page-content" style={{marginTop:'100px'}}>
                <PageLayout pageTitle="About Us" />               
            
     <section className="content-inner p-0 bg-white-always  video-bx-wrapper">
                    <img className="bg-shape1" src={Shape1} alt="" />
                    <div className="container wow fadeInUp" data-wow-delay="0.4s">
                        <div className="video-bx style-1">
                            <div className="video-media ">
                                <img src={videobox} alt="" />
                                <Link to={"#"} className="popup-youtube play-icon " onClick={()=> setOpen(true)}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 3.75L23.75 15L6.25 26.25V3.75Z" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
      </section>
                
                <section className="content-inner bg-light icon-section section-wrapper2">
					<div className="container">
						<div className="section-ourmission text-center">
							<h2 className="title" style={{ color: '#2C0882 '}}>OUR MISSION</h2>
							<p className='section-text-our'>In a world where global wealth is controlled largely by big institutions and governments, ZARC aims to provide a more decentralized solution that gives power back to the people.</p>
						</div>
            </div>
            </section>
            
            <section className="content-inner icon-section section-wrapper2" style={{background:'white'}}>
            <div className="container">
						<div className=" text-center">
						<div className="row sp60">
							<OneStop />
						</div>
            </div>
            </div>
					<img className="bg-shape1" src={Shape1} alt="" />
				</section>
   

                {/* <section className="content-inner bg-light pricing-plan-wrapper2">
  <img className="bg-shape2" src={Shape1} alt="" />
  <div className="container">
    <div className="section-head text-center">
      <h2 className="title"> Our Team </h2>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-4 col-sm-6">
        <div className="pricing-plan-2 bg-white" style={{border: "2px solid white" , clipPath: "polygon(0px 0px, calc(100% - 15px) 0px, 100% 15px, 100% 100%, -2px 100.23%, 0px calc(100% - 15px))"}}>
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/john-doe"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember1} alt="Team Member 1" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Hanres Beukes</h5>
             <p className="team-position">Global Group Ceo & Co-Founder</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="col-md-4 col-sm-6">
      <div className="pricing-plan-2 bg-white team-border">
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/jane-smith"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember2} alt="Team Member 2" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Kyle Mizen</h5>
             <p className="team-position">Global Group Managing Director and Head of Operations</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
 
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
      <div className="pricing-plan-2 bg-white team-border">
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/jane-smith"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember3} alt="Team Member 2" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Charne</h5>
             <p className="team-position">Global Head of Marketing</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
      <div className="pricing-plan-2 bg-white team-border">
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/jane-smith"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember4} alt="Team Member 2" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Denise Van Vuuren</h5>
             <p className="team-position">CEO</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
      <div className="pricing-plan-2 bg-white team-border">
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/jane-smith"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember5} alt="Team Member 2" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Henro Van Der Schyff</h5>
             <p className="team-position">Graphic Designer & Junior Marketing Consultant</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
      <div className="pricing-plan-2 bg-white team-border">
          <div className="thumb text-center" style={{position: "relative"}}>
            <a href="https://www.linkedin.com/in/jane-smith"><img className="img-fluid" style={{width: "100%", margin: "0 auto"}} src={TeamMember6} alt="Team Member 2" /></a>
            <div style={{position: "absolute", bottom: "10px", left: "0", width: "100%", backgroundColor: "#000", padding: "10px 0"}}>
            <h5 className="team-heading">Killerpoe</h5>
             <p className="team-position">Graphic Designer & Junior Marketing Consultant</p>
             <p className="team-description">(EMEA/UK/USA/SG)</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>    
   </section> */}
                {/* <section className="content-inner bg-light blog-wrapper">
                    <img className="bg-shape1" src={Shape1} alt="" />
                    <div className="container">
						<div className="row">
							<div className="col-xl-7 col-lg-12">
								<div className="section-head " >
									<h6 className="sub-title text-primary">FROM OUR BLOG</h6>
									<h2 className="title">Recent News &amp; Updates</h2>
								</div>
								<RecentNews />
							</div>
							<div className="col-xl-5 col-lg-12 m-b30 " >
								<div className="dz-card style-2" style={{backgroundImage: "url("+ bloglg +")"}}>
									<div className="dz-category">
										<ul className="dz-badge-list">
											<li><Link to={"#"} className="dz-badge">14 Fan 2022</Link></li>
										</ul>
									</div>
									<div className="dz-info">
										<h2 className="dz-title"><Link to={"/blog-details"} className="text-white">Directly support individuals Crypto</Link></h2>
										<div className="dz-meta">
											<ul>
												<li className="post-author">
													<Link to={"#"}>
														<img src={avatar3} alt=""  className="me-2"/>
														<span>By Noare</span>
													</Link>
												</li>
												<li className="post-date"><Link to={"#"}> 12 May 2022</Link></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                </section> */}
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="MVpIJEilW5I" onClose={() => setOpen(false)} />
        </>
    )
}
export default AboutUs;